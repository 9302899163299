import React from 'react'
import Link from 'gatsby-link'
import styles from '../widget-mostpopular/widget-mostpopular.module.css'

const WidgetMostpopular = (props) => (
  <div>
  {(props.mostPopular !== undefined && props.mostPopular !== 0) ?
    <div>
      <h3 className ={styles.widgetMostpopularTitle}>Most Popular</h3>
      <div className={styles.widgetMostpopular}>
        <ul className={styles.categotyList}>
          {props.mostPopular.map(item => {
            return item[1].includes("Software Development and Programming") ? null : <li key={item[0]}><Link to={item[0]} className={styles.mostpopularArticle}>{item[1].replace(" | CodeSpot",'')}</Link></li>
          })}
        </ul>
      </div>
    </div>:
    <div className="d-flex justify-content-center">
    <div className="spinner-border m-5 text-danger" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    </div>}
  </div>
)

export default WidgetMostpopular